import { DateTime, Duration } from 'luxon';

import {
  ExclusiveListingInfo,
  OpenHouse,
} from '../../declarations/exclusives/exclusiveListingInfo';

export function remainingDurationToEndTime(
  exclusiveListingInfo: ExclusiveListingInfo,
  now = DateTime.now(),
): Duration | undefined {
  const endTime =
    exclusiveListingInfo?.end_time && DateTime.fromISO(exclusiveListingInfo?.end_time);
  if (!endTime || now > endTime) return undefined;
  return endTime.diff(now).rescale();
}

// Dealing with possible undefined values of the start_time
// and returning always a number that represents the amount of days until the end_time
export function remainingDaysRoundedToEndTime(
  exclusiveListingInfo: ExclusiveListingInfo,
  now = DateTime.now(),
): number | undefined {
  if (!exclusiveListingInfo) return undefined;
  const remainingDuration = remainingDurationToEndTime(exclusiveListingInfo, now);
  if (!remainingDuration) return undefined;
  return Math.round(remainingDuration.as('days'));
}

export function getEarliestOpenHouse(
  exclusive_listing_info: ExclusiveListingInfo,
): OpenHouse | undefined {
  if (!exclusive_listing_info) return undefined;

  const ongoing_and_upcoming_open_houses = exclusive_listing_info.ongoing_and_upcoming_open_houses;
  if (!ongoing_and_upcoming_open_houses || !ongoing_and_upcoming_open_houses.length)
    return undefined;
  let openHouse = ongoing_and_upcoming_open_houses[0];
  let earliestStartTime = DateTime.fromISO(openHouse.start_time);
  for (let i = 1; i < ongoing_and_upcoming_open_houses.length; i++) {
    const startTime = DateTime.fromISO(ongoing_and_upcoming_open_houses[i].start_time);
    if (startTime < earliestStartTime) {
      earliestStartTime = startTime;
      openHouse = ongoing_and_upcoming_open_houses[i];
    }
  }
  return openHouse;
}
