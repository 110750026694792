import { useCallback, useEffect, useRef } from 'react';

import { Listing } from '../../../declarations/exclusives/listing';
import { getExclusivesEventHomeInfo } from '../../../helpers/exclusives/listing';
import { useObservability } from '../../../helpers/observability';
import { DEPLOY_ENV } from '../../globals';
import { getIsMobile } from '../helpers/mobile';

export type ExclusivesFeatureName = `exclusives-${string}` &
  Parameters<ReturnType<typeof useObservability>['trackEvent']>[0];

function useExclusiveAnalytics() {
  const analytics = useObservability();
  const deviceType = useRef('desktop');

  // Set the device type on mount
  useEffect(() => {
    if (getIsMobile()) {
      deviceType.current = 'mobile';
    }
  }, []);

  const trackExclusivesEvent = useCallback(
    function <T>(
      featureName: ExclusivesFeatureName,
      label: string,
      listing?: Listing,
      additionalContextualData: T = {} as T,
    ) {
      const listingInformation = {
        city: listing?.address?.city,
        slug: listing?.slug,
        market: listing?.address?.market?.identifier,
      };

      const payload = {
        additionalContextualData: {
          device_type: deviceType.current,
          ...additionalContextualData,
          ...(listing && getExclusivesEventHomeInfo(listing)),
        },
        ...(listing && listingInformation),
      };

      analytics.trackEvent(featureName, label, payload);

      if (DEPLOY_ENV === 'development') {
        console.debug(`[Analytics]`, { featureName, label, payload });
      }
    },
    [analytics.trackEvent],
  );

  return { trackExclusivesEvent };
}

export default useExclusiveAnalytics;
